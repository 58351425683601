@import "../../assets/styles/app";

.root {
	@include flex();

	//position: sticky;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	z-index: 1000;
	height: 83px;
	width: 100%;
	background: rgba(10, 6, 23, .7);
	box-shadow: 0px 4px 4px rgba(58, 136, 247, 0.1);

	.backButton {
		@include flex();
		width: 162px;
		position: absolute;
		left: 0;

		span {
			flex: 1;
			text-align: center;
		}
	}

	.iconsContainer {
		@include flex();
		height: 100%;
		justify-content: space-between;
		position: absolute;
		left: 10%;
		top: 0;
		min-width: 104px;

		.icon {
			cursor: pointer;
			width: 48px;
		}

		.icon + .icon {
			margin-left: 8px;
		}
	}

	/* SMALL SCREENS */
	& {
		@media (max-width: "1080px") {
			.backButton {
				left: 40px;
			}

			.iconsContainer {
				left: 40px;
			}
		}

		@media (max-width: $screen-sm-min) {
			.iconsContainer {
				left: 20px;
				right: 20px;
				justify-content: flex-start;

				.icon:last-child {
					margin-left: auto;
				}
			}

			.backButton {
				left: 20px;
			}
		}
	}
}
