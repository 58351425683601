/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root {
  display: inline-block;
}

.root span {
  margin-left: 4px;
  font-family: "Play-Bold";
  color: #E9ECE7;
  font-weight: bold;
  font-style: bold;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: -0.02em;
}

