/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root {
  background-image: url("../../../assets/images/town.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.root .content .title {
  font-family: "Audiowide-Regular";
  font-weight: normal;
  color: #E9ECE7;
  font-style: normal;
  font-size: 60px;
  line-height: auto;
  letter-spacing: 0%;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
  -webkit-text-stroke: 1px black;
  color: rgba(0, 0, 0, 0);
  text-align: left;
  margin-bottom: 20px;
  color: #E9ECE7;
  font-size: 30px;
  max-width: 80%;
}

.root .content .description {
  font-family: "Play-Bold";
  font-weight: 700;
  color: var(--cpai-white);
  font-style: bold;
  font-size: 16px;
  line-height: auto;
  letter-spacing: -0.02em;
  margin-bottom: 16px;
  text-align: left;
  word-wrap: break-word;
  white-space: pre-wrap;
  max-width: 80%;
}

.root .content .centeredContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

