@import "../../assets/styles/app";

.root {
	@include flex-column();
	margin-left: auto;
	margin-right: auto;
	width: 540px;
	max-width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;

	.sectionTitle {
		@include font-header3(var(--app-color), "Poppins-Medium", 600);
		margin-bottom: 4px;
	}

	.text {
		@include font-header3($gray-100);
		line-height: normal;
	}

	.copyWidget {
		margin-bottom: 0;
	}
}
