/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root {
  min-height: 100vh;
}

.root .content {
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding: 100px min(10%, 165px);
}

@media (max-width: 1200px) {
  .root .content {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 768px) {
  .root .content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.root .content .title {
  font-family: "Audiowide-Regular";
  font-weight: normal;
  color: #80B63C;
  font-style: normal;
  font-size: 60px;
  line-height: auto;
  letter-spacing: 0%;
  text-align: center;
}

@media (max-width: 768px) {
  .root .content .title {
    font-family: "Audiowide-Regular";
    font-weight: normal;
    color: #80B63C;
    font-style: normal;
    font-size: 20px;
    line-height: auto;
    letter-spacing: 0%;
    margin-bottom: 16px;
  }
}

.root .content .infos {
  margin-top: 8px;
}

.root .content .infos .description {
  font-family: "Poppins";
  color: var(--gray-100);
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.root .content .infos .description.mb {
  margin-bottom: 8px;
}

.root .content .infos .description.mt {
  margin-top: 16px;
}

.root .content .buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.root .content .buttons .button {
  width: 350px;
}

