.root .title {
  font-family: "Audiowide-Regular";
  font-weight: normal;
  color: var(--cpai-white);
  font-style: normal;
  font-size: 20px;
  line-height: auto;
  letter-spacing: 0%;
  margin-bottom: 16px;
  text-align: center;
}

.root .logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.root .description {
  font-family: "Play-Regular";
  font-weight: 700;
  color: var(--cpai-white);
  font-style: normal;
  font-size: 16px;
  line-height: auto;
  letter-spacing: -0.02em;
  text-align: center;
}

.root .buttons button {
  min-width: 117px;
}

.root .buttons button:first-child {
  margin-right: 8px;
}

