@import "../../../assets/styles/app";

.root {
	.title {
		@include font-header2($cpai-white);
		text-align: center;
	}

	.current {
		@include flex();

		img {
			max-width: 110px;
			max-height: 110px;
		}
	}

	.footer {
		padding: 0 28px;
		width: 100%;

		.steps {
			@include flex-left();
			justify-content: space-between;
			margin-top: 24px;

			.step {
				@include flex-column();
				position: relative;
				background-color: $cpai-black;
				flex: 1;
				cursor: pointer;

				.name {
					@include font-text($cpai-white, "Play-Regular");
				}

				.link {
					width: 200px;
					max-width: 100%;
					height: 1px;
					position: absolute;
					right: 50%;
					top: 9px;
					border-top: solid 2px $cpai-white;
				}

				img {
					z-index: 100;
				}

				&:first-child {
					.link {
						display: none;
					}
				}

				&.active {
					.name {
						color: var(--cpai-cyan);
					}

					.link {
						border-top-color: var(--cpai-white);
						margin-top: 10px;
					}
				}
			}
		}

		.description {
			@include font-text($cpai-white);
			text-align: center;
			margin-top: 16px;
			margin-bottom: 16px;
		}
	}
}
