/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root {
  padding: 10px 15px 9px 15px;
  position: relative;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.root .logo {
  max-width: 350px;
}

@media (max-width: 768px) {
  .root .logo {
    max-width: 200px;
  }
}

