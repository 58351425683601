/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root {
  width: 100%;
  margin-bottom: 16px;
}

.root .title {
  font-family: "Poppins";
  font-weight: 600;
  color: #78909c;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.root .copyWidget {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
}

.root .copyWidget:hover .text {
  letter-spacing: 0.04em !important;
  color: #80B63C !important;
  font-weight: 600 !important;
}

.root .copyWidget:hover svg path {
  stroke: #80B63C;
}

.root .copyWidget .copyIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 4px;
}

