/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root {
  min-height: 100vh;
  justify-content: flex-start;
}

.root .tabs {
  width: 100%;
}

.root .content {
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding: 100px min(10%, 165px);
  position: relative;
}

@media (max-width: 1200px) {
  .root .content {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 768px) {
  .root .content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.root .content .title {
  font-family: "Audiowide-Regular";
  font-weight: normal;
  color: var(--app-color);
  font-style: normal;
  font-size: 60px;
  line-height: auto;
  letter-spacing: 0%;
  text-align: center;
}

