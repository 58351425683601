@import "../../../assets/styles/app";

.root {
	@include page-background('../../assets/images/town.png');
	
	.content {

		.title {
			@include font-header-glow($cpai-white);
			text-align: left;
			margin-bottom: 20px;
			color: $cpai-white;
			font-size: 30px;
			max-width: 80%;
		
		}

		.description {
			@include font-text-bold(var(--cpai-white));
			margin-bottom: 16px;
			text-align: left;
			word-wrap: break-word;
			white-space: pre-wrap;
			max-width: 80%;
			
		}
	
		.centeredContent {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		
	}

}