@import "../../../assets/styles/app";

.root {
	@include flex();

	.highlight {
		background-color: rgba(209, 209, 209, .5);
		font-weight: normal;
	}
}
