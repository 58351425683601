@import "../../assets/styles/_mixins";

.container {
  max-width: 80%; /* Adjust max-width as needed */
  margin: 0 auto; /* Center the container horizontally */
  background-color: rgba(10, 6, 23, 0.7); /* Background color */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Add padding around the content */


  @media (max-width: 768px) {
      width: 100%; /* Full width for single column layout */
  }
  


  @media (max-width: 768px) {
      flex-direction: row; /* Single column layout for smaller screens */
      align-items: center; /* Center items vertically */
      padding: 10px; /* Adjust padding for smaller screens */
  }

.responsive-image {
  max-width: 100%; /* Make the image responsive */
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

}