/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root {
  max-width: 100%;
}

.root .button {
  width: 350px;
  max-width: 100%;
  height: 48px;
  text-align: center;
}

