@import "../../assets/styles/app";


body {
	background-color: $cpai-black;
	background-image: none;
	width: 100vw;
}


.root {
	

	.content {
		

		.title {
			@include font-header-glow($cpai-white);
			text-align: left;
			color: $cpai-white;
		}

		.subtitle {
			@include font-header2($cpai-white);
			text-align: left;
		}

		.description {
			@include font-text($cpai-white);
			text-align: left;
			max-width: auto;
			word-wrap: break-word;

			&.mb {
				margin-bottom: 8px;
			}

			&.mt {
				margin-top: 24px;
			}
		}

		.buttons {
			@include flex-left();
			margin-top: 24px;
			max-width: 100%;
		}

		.horizontal-line {
			border-top: 1px solid $cpai-gray;
			width: 100%;
			margin: 40px 0;
		}

		.responsive-image {
			max-width: 100%; /* Make the image responsive */
			height: auto;
		  }

		@media (max-width: $screen-sm-min) {
			padding-top: 40px; // Add top padding for smaller breakpoints
		}
		}
	}