/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.desc {
  margin: 0 0 0 10px;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 380px;
  padding: 30px;
  border-radius: 16px;
  border: 1px solid var(--grey-2, #fbfbfb);
  box-shadow: 0 0 10px rgba(186, 243, 240, 0.7);
  max-width: 100%;
  background-color: #BAF3F0;
}

.root .title {
  font-family: "Audiowide-Regular";
  font-weight: normal;
  color: var(--cpai-blue);
  font-style: normal;
  font-size: 20px;
  line-height: auto;
  letter-spacing: 0%;
  margin-bottom: 16px;
  margin-bottom: 10px;
}

.root img {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  max-height: 300px;
}

