/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
  height: 83px;
  width: 100%;
  background: rgba(10, 6, 23, 0.7);
  box-shadow: 0px 4px 4px rgba(58, 136, 247, 0.1);
  /* SMALL SCREENS */
}

.root .backButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 162px;
  position: absolute;
  left: 0;
}

.root .backButton span {
  flex: 1;
  text-align: center;
}

.root .iconsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  justify-content: space-between;
  position: absolute;
  left: 10%;
  top: 0;
  min-width: 104px;
}

.root .iconsContainer .icon {
  cursor: pointer;
  width: 48px;
}

.root .iconsContainer .icon + .icon {
  margin-left: 8px;
}

@media (max-width: 1080px) {
  .root .backButton {
    left: 40px;
  }

  .root .iconsContainer {
    left: 40px;
  }
}

@media (max-width: 768px) {
  .root .iconsContainer {
    left: 20px;
    right: 20px;
    justify-content: flex-start;
  }

  .root .iconsContainer .icon:last-child {
    margin-left: auto;
  }

  .root .backButton {
    left: 20px;
  }
}

