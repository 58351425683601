html,
body,
.app,
#root {
	height: auto;
	height: 100%;
	width: 100%;
}

a {
	cursor: pointer;
}

p {
	margin-bottom: 0;
}

span.mandatory {
	color: $danger;
}

label {
	margin-bottom: 0;
}

/* Tooltip */
.tooltip-inner {
	&.wide {
		max-width: 340px;
	}

	p.tooltip-text {
		@include font-text($white-color);
		white-space: pre-wrap;

		a {
			text-decoration: underline;
			color: $white-color;
		}
	}
}

/* Highlight */
.searchHighlight {
	color: #1a1c21;
	background-color: #faff07;
	font-weight: 600;
}

/* Cards */
.card {
	.card-title {
		@include font-body20($gray-900);
	}

	.card-subtitle {
		@include font-body16($gray-900);
	}
	.card-body {
		padding: 20px;
	}
}
