@import "../../../assets/styles/app";

.root {
	$margin: 10%;
	height: 100%;

	display: flex;
	flex-direction: column;

	.content {
		margin: 40px $margin 0;

		.title {
			@include font-header1($bad);
			text-align: center;
		}

		.description {
			@include font-body16(var(--gray-100));
			margin-bottom: 16px;
			text-align: center;
		}

		.buttons {
			@include flex();
			margin-top: 40px;

			.button {
				width: 350px;
			}
		}

		.image {
			@include flex();
			margin-top: 40px;
		}
	}

	@media (max-width: "1080px") {
		.header {
			padding: 81px 40px 20px;
		}

		.content {
			margin: 20px 40px;
		}
	}

	@media (max-width: "700px") {
		.header {
			padding: 81px 16px 16px;
		}

		.content {
			margin: 20px 16px;
		}
	}
}
