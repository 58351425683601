/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root {
  position: relative;
  overflow: visible;
}

.root .titleHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
}

.root .titleHeader img {
  cursor: pointer;
}

.root .titleHeader .total {
  font-family: "Poppins";
  color: #1a1c21;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.02em;
}

.root .title {
  font-family: "Audiowide-Regular";
  font-weight: normal;
  color: #1a1c21;
  font-style: normal;
  font-size: 20px;
  line-height: auto;
  letter-spacing: 0%;
  margin-bottom: 16px;
}

.root .mainTable {
  height: 100%;
}

.root .mainTable .dotRow {
  display: flex;
  align-items: center;
}

.root .mainTable .dotRow .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  margin-right: 6px;
}

.root .mainTable .centerTh {
  text-align: center !important;
}

.root .mainTable .rightAlignTh {
  text-align: right !important;
  padding-right: 10px;
}

.root .mainTable .brokerTh {
  padding-right: 0;
}

.root .mainTable .blacklisted {
  color: #d07567;
}

.root .mainTable .row {
  height: 54px;
}

.root .mainTable td,
.root .mainTable td > * {
  overflow: visible;
}

.root .mainTable th {
  font-family: "Poppins";
  color: #2d3540;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.02em;
  user-select: none;
  /* Mozilla-specific values */
  -moz-user-select: none;
  /* WebKit-specific values */
  -webkit-user-select: none;
  /* Microsoft-specific values */
  -ms-user-select: none;
  border: none;
  height: 50px;
  padding-left: 8px;
  padding-right: 8px;
}

.root .mainTable td {
  border: none !important;
  font-family: "Poppins";
  color: #1a1c21;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.02em;
  vertical-align: middle;
  padding-left: 8px;
  padding-right: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.root .mainTable td .rightsCell {
  display: flex;
  flex-direction: row;
}

.root .mainTable td .centerCell {
  display: flex;
  justify-content: center;
}

.root .mainTable td.broker {
  padding-right: 0;
}

.root .mainTable .description {
  font-family: "Play-Regular";
  color: #1a1c21;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.02em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.root .mainTable .emptyDescription {
  color: #a3a4a6;
}

.root .mainTable .hover:hover {
  background-color: #f5fafe;
}

.root .mainTable .hover td.highlight {
  cursor: pointer;
}

.root .mainTable .hover td.highlight:hover {
  font-family: "Poppins-Medium";
  color: var(--app-color);
  letter-spacing: 0.04em;
}

.root .mainTable .hover td.highlight:hover > a {
  color: var(--app-color);
}

.root .mainTable .hover td.highlight span:hover,
.root .mainTable .hover td.highlight div:hover {
  font-family: "Poppins-Medium";
  color: var(--app-color);
  letter-spacing: 0.04em;
}

.root .mainTable .hover td.highlight > a {
  cursor: pointer;
  color: #1a1c21;
  font-weight: 600;
  text-decoration: none;
}

.root .mainTable .hover.selected {
  background-color: #f5fafe;
}

.root .mainTable .hover.selected svg path {
  stroke: #50b0f9;
}

.root .mainTable .hover.selected {
  background-color: #f5fafe;
}

.root .tableCell {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  font-family: "Play-Regular";
  color: #1a1c21;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.02em;
  text-decoration: none;
  cursor: pointer;
}

.root .actions {
  display: flex;
  align-items: center;
}

.root .actions .tableFillIcon svg,
.root .actions .deleteIcon svg,
.root .actions .tableStrokeIcon svg {
  width: var(--icon-size);
  height: var(--icon-size);
}

.root .actions .tableFillIcon,
.root .actions .tableStrokeIcon {
  margin-right: var(--margin);
  display: flex;
  align-items: center;
  justify-content: center;
}

.root .actions .tableStrokeIcon:hover svg path {
  stroke: #50b0f9;
}

.root .actions .tableFillIcon:hover svg path {
  fill: #50b0f9;
}

.root .actions .deleteIcon:hover svg path {
  fill: #dc3545;
}

.root .pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 30px;
  height: 40px;
}

.root .pagination ul li:last-child a {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

