@import "../../assets/styles/app";

.root {
	position: relative;
	flex: 1;

	.title {
		@include font-header5($gray-100, 600);
		margin-bottom: 2px;

		&.error {
			color: $danger !important;
		}
	}

	.input {
		@include font-header2($gray-100, "Poppins", 400);
		width: 100%;
		max-width: 100%;
		height: 46px;
		border-radius: 4px;
		padding: 0 10px;
		border: 1px solid #ced4da;

		&.error {
			border-color: $danger;
		}

		&:disabled {
			background-color: $white-10;
		}

		&::placeholder {
			@include font-header2($gray-40, "Poppins-Light", 400);
		}
	}

	.label {
		@include font-body12($gray-80);
		margin-top: 4px;

		span {
			display: inline-block;
			font-family: "Poppins-Bold";
		}
	}

	.errorMessage {
		@include font-body12($danger);
		margin-top: 4px;
	}
}
