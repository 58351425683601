/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  font-family: "Play-Regular";
  font-weight: normal;
  color: #E9ECE7;
  font-style: normal;
  font-size: 16px;
  line-height: auto;
  letter-spacing: -0.02em;
}

.root .highlighter,
.root .highlighter div {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.root span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}

.root .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  margin-bottom: 2px;
}

.root.rightCell {
  display: inline-flex;
  width: 100%;
  padding-right: 10px;
  justify-content: flex-end;
}

