/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.btnSuccess {
  background-color: var(--app-color) !important;
  border-color: var(--app-color) !important;
  color: #ffffff !important;
}

.btnFailure {
  background-color: var(--error-color) !important;
  border-color: var(--error-color) !important;
  color: #ffffff !important;
}

.root {
  height: auto;
  max-width: 100%;
}

.root .header {
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 16px;
  margin-right: 16px;
}

.root .header .search {
  max-width: 300px;
}

.root .header .selector {
  position: relative;
  font-family: "Play-Regular";
  color: #1a1c21;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.02em;
  margin-left: auto;
}

@media (max-width: 768px) {
  .root .header .selector {
    text-align: right;
    width: 160px;
  }
}

.root .header .selector img {
  position: absolute;
  left: -25px;
  top: 0;
  max-width: 20px;
}

.root .header .selector * {
  cursor: pointer;
}

.root .header .selector input {
  margin-right: 8px;
}

