/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root {
  border: 2px solid #E9ECE7;
  border-radius: 4px;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 348px;
  max-width: 100%;
}

.root .foxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 14px;
  height: 80px;
  cursor: pointer;
}

.root .foxContainer .foxRighPanel {
  margin-left: 20px;
  margin-right: 20px;
  flex: 1;
}

.root .foxContainer .foxRighPanel .meta {
  font-family: "Audiowide-Regular";
  font-weight: 600;
  color: #E9ECE7;
  font-style: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.02em;
}

.root .foxContainer .foxRighPanel .subTitle {
  font-family: "Play-Regular";
  color: #E9ECE7;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.02em;
}

.root .download {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E9ECE7;
  height: 70px;
}

.root .download p {
  font-family: "Play-Regular";
  font-weight: 700;
  color: #0A0617;
  font-style: normal;
  font-size: 16px;
  line-height: auto;
  letter-spacing: -0.02em;
}

.root .download p a {
  color: var(--cpai-blue);
  letter-spacing: 0.04em;
}

