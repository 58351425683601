/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root .content .title {
  font-family: "Audiowide-Regular";
  font-weight: normal;
  color: #E9ECE7;
  font-style: normal;
  font-size: 60px;
  line-height: auto;
  letter-spacing: 0%;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
  -webkit-text-stroke: 1px black;
  color: rgba(0, 0, 0, 0);
  text-align: left;
  color: #E9ECE7;
  margin-top: 160px;
}

.root .content .subtitle {
  font-family: "Audiowide-Regular";
  font-weight: normal;
  color: #E9ECE7;
  font-style: normal;
  font-size: 20px;
  line-height: auto;
  letter-spacing: 0%;
  margin-bottom: 16px;
  text-align: left;
}

.root .content .description {
  font-family: "Play-Regular";
  font-weight: 700;
  color: #E9ECE7;
  font-style: normal;
  font-size: 16px;
  line-height: auto;
  letter-spacing: -0.02em;
  text-align: left;
  max-width: auto;
  word-wrap: break-word;
}

.root .content .description.mb {
  margin-bottom: 8px;
}

.root .content .description.mt {
  margin-top: 24px;
}

.root .content .buttons {
  display: flex;
  align-items: center;
  margin-top: 24px;
  max-width: 90%;
}

.root .inputContainer {
  display: flex;
  align-items: center;
  margin-top: 40px;
  max-width: 100%;
  max-width: 90%;
}

.root .inputContainer .input {
  width: 540px;
  max-width: 100%;
  justify-content: left;
  max-width: 90%;
}

.root .inputContainer .input input {
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.root .inputContainer .inputTitle {
  font-family: "Play-Bold";
  font-weight: 700;
  color: #E9ECE7;
  font-style: bold;
  font-size: 16px;
  line-height: auto;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 8px;
}

.root .inputContainer .responsive-image {
  max-width: 50%;
  /* Make the image responsive */
  height: auto;
}

