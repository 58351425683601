// BUTTONS
@mixin page-layout() {
	min-height: 100vh;
}

@mixin page-content($justify-content: left) {
	$padding: min(10%, 165px);
	display: flex;
	align-items: left;
	flex-direction: column;
	justify-content: $justify-content;
	height: 100%;
	width: 100%;
	padding: 100px $padding;

	@media (max-width: $screen-lg-min) {
		padding-left: 40px;
		padding-right: 40px;
	}

	@media (max-width: $screen-sm-min) {
		padding-left: 20px;
		padding-right: 20px;
	}
}

// FONTS
@mixin font-header1($color, $family: "Audiowide-Regular", $weight: normal) {
	font-family: $family;
	font-weight: $weight;
	color: $color;

	font-style: normal;
	font-size: 60px;
	line-height: auto;
	letter-spacing: 0%;
}

@mixin font-header-glow($color, $family: "Audiowide-Regular", $weight: normal) {
	font-family: $family;
	font-weight: $weight;
	color: $cpai-white;
	font-style: normal;
	font-size: 60px;
	line-height: auto;
	letter-spacing: 0%;
	text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
	-webkit-text-stroke: 1px black;color: rgba(0,0,0,0)
}

@mixin font-header2($color, $family: "Audiowide-Regular", $weight: normal) {
	font-family: $family;
	font-weight: $weight;
	color: $color;

	font-style: normal;
	font-size: 20px;
	line-height: auto;
	letter-spacing: 0%;
	margin-bottom: 16px;
}

@mixin font-header3($color, $family: "Audiowide-Regular", $weight: normal) {
	font-family: $family;
	font-weight: $weight;
	color: $color;

	font-style: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.02em;
}

@mixin font-header4($color, $weight: 600, $family: "Audiowide-Regular") {
	font-family: $family;
	font-weight: $weight;
	color: $color;

	font-style: normal;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: -0.02em;
}

@mixin font-header5($color, $family: "Poppins-Medium", $weight: 600) {
	font-family: $family;
	font-weight: $weight;
	color: $color;
	font-style: normal;

	font-weight: $weight;
	font-size: 12px;
	line-height: 18px;
}

@mixin font-text-bold($color, $family: "Play-Bold", $weight: 700, $style: bold) {
	font-family: $family;
	font-weight: $weight;
	color: $color;
	font-style: $style;
	font-size: 16px;
	line-height: auto;
	letter-spacing: -0.02em;
}

@mixin font-text($color, $family: "Play-Regular", $weight: 700, $style: normal) {
	font-family: $family;
	font-weight: $weight;
	color: $color;
	font-style: $style;
	font-size: 16px;
	line-height: auto;
	letter-spacing: -0.02em;
}

@mixin font-body20($color, $family: "Poppins", $weight: 400) {
	font-family: $family;
	color: $color;
	font-weight: $weight;
	font-style: normal;
	font-size: 20px;
	line-height: 30px;
	letter-spacing: -0.02em;
}

@mixin font-body16($color, $fontFamily: "Poppins") {
	font-family: $fontFamily;
	color: $color;
	font-weight: 400;
	font-style: normal;
	font-size: 16px;
	line-height: 24px;
}

@mixin font-body14($color, $family: "Play-Regular", $weight: 400) {
	font-family: $family;
	color: $color;
	font-weight: $weight;
	font-style: normal;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: -0.02em;
}

@mixin font-body12($color, $family: "Poppins", $weight: 400) {
	font-family: $family;
	color: $color;
	font-weight: $weight;
	font-style: normal;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: -0.02em;
}

@mixin font-body8($color, $family: "Poppins", $weight: 400) {
	font-family: $family;
	color: $color;
	font-weight: $weight;
	font-style: normal;
	font-size: 8px;
	line-height: 12px;
	letter-spacing: -0.02em;
}

@mixin font-label($color, $family: "Poppins", $weight: bold) {
	font-family: $family;
	color: $color;
	font-weight: $weight;
	font-style: normal;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.04em;
}

@mixin font-caption($color, $family: "Play-Bold", $weight: bold) {
	font-family: $family;
	color: $color;
	font-weight: bold;

	font-style: bold;
	font-size: 10px;
	line-height: 15px;
	letter-spacing: -0.02em;
}

@mixin ellipsis($rows: 1) {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: $rows;
	-webkit-box-orient: vertical;
}

@mixin ellipsis-text {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

// BUTTONS
@mixin btn-styles($color, $text-color) {
	@include font-button-text($text-color);

	border-color: $cpai-black;
	background-color: $cpai-white;
	color: $cpai-black;

	&:focus,
	&:active,
	&:hover {
		color: $cpai-black;
		transform: translate(-0.25rem, -0.25rem);
		background: $cpai-cyan;
		box-shadow: 0.25rem 0.25rem #0A0617;
	}
	&.active {
		border-color: $color;
		//background-color: $color;
		color: $text-color;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
		outline: none;
	}

	&.disabled,
	&:disabled,
	&[disabled] {
		&,
		&:hover,
		&:focus,
		&.focus,
		&:active,
		&.active {
			color: $gray-20 !important;
			background-color: $gray-60 !important;
			border: $gray-60;
			opacity: 1;
		}
	}
}

@mixin font-button-text($color, $family: "Play-Bold", $weight: 700) {
	font-family: $family;
	color: $color;
	font-weight: $weight;
	font-style: bold;
	font-size: 12px;
	line-height: auto;
	letter-spacing: 0.04em;
}

@mixin border-btn-styles($color, $text-color, $hover-color) {
	@include font-button-text($color);

	border-color: $cpai-white;
	border-width: 2px;
	background-color: transparent;
	color: $cpai-white;

	&:focus,
	&:active,
	&.active {
		border-color: $color;
		background-color: $color;
		color: $hover-color;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
		outline: none;
	}

	&:hover {
		color: $cpai-black;
		transform: translate(-0.25rem, -0.25rem);
		background: $cpai-cyan-light;
		box-shadow: 0.25rem 0.25rem $cpai-white;
	}

	&:hover {
		color: $hover-color;
		background-color: $color;
	}

	&.disabled,
	&:disabled,
	&[disabled] {
		&,
		&:hover,
		&:focus,
		&.focus,
		&:active,
		&.active {
			background-color: $text-color !important;
			color: $gray-60 !important;
			border: solid 2px $gray-60;
		}
	}
}

@mixin btn-size($padding-vertical, $padding-horizontal, $font-size, $border) {
	font-size: $font-size;
	border-radius: $border;
	padding: $padding-vertical $padding-horizontal;

	&.btn-round {
		padding: $padding-vertical + 1 $padding-horizontal;
	}

	&.btn-simple {
		padding: $padding-vertical + 2 $padding-horizontal;
	}
}

@mixin header-button($color: $gray-100) {
	width: 160px;
	height: 48px;
	background: #52e8cd;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
	border-radius: 4px;

	@include font-button-text($gray-100);
	color: $color !important;
	border: none;

	margin-left: 30px;

	i {
		margin-right: 10px;
	}
}

// DISPLAY


@mixin grid($cols: 2, $gap: 30, $minWidth: 0, $maxWidth: 900px) {
    display: grid;
    gap: $gap;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
	max-width: $maxWidth;
	justify-content: center;
	
	.column {
	width: 48%; /* Equal width for both columns */
	}

	@media (max-width: 768px) {
        grid-template-columns: 1fr; // Switch to one column on smaller breakpoints
    }
}

@mixin flex() {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin flex-left() {
	display: flex;
	align-items: center;
}

@mixin flex-row() {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin flex-column() {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

@mixin flex-right() {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

// INPUTS
@mixin input-size($padding-vertical, $padding-horizontal, $height) {
	padding: $padding-vertical $padding-horizontal;
	height: $height;
}

@mixin placeholder($color: $gray-40, $opacity: 1) {
	&::placeholder {
		color: $color;
		opacity: $opacity;
	}
}

@mixin user-select() {
	user-select: none;

	/* Mozilla-specific values */
	-moz-user-select: none;

	/* WebKit-specific values */
	-webkit-user-select: none;
	/* Microsoft-specific values */
	-ms-user-select: none;
}

// TABLE
@mixin highlight-table-cell($color) {
	font-family: "Poppins-Medium";
	color: $color;
	letter-spacing: 0.04em;
}

//CONTAINER
@mixin container($background-color, $padding-vertical, $padding-horizontal, $margin-vertical, $margin-horizontal) {
    background-color: $background-color;
    padding: $padding-vertical $padding-horizontal;
	margin: $margin-vertical $margin-horizontal;
	border-radius: 10px;
    justify-content: center;
    align-items: center;
	width: 90%;
	max-width: 90%;
}


//BACKGROUND IMAGE
@mixin page-background($image-url) {
        background-image: url($image-url);
        background-size: cover;
        background-position: center;
		background-repeat: no-repeat;

    }
