.Toastify__toast-container {
	.Toastify__toast-body {
		@include font-body16($white-color);
	}

	.Toastify__close-button--success {
		color: $gray-100;
	}

	.Toastify__toast--success {
		background-color: var(--app-color);
		border-radius: 4px;

		.Toastify__progress-bar {
			background-color: #cdeda5;
		}
	}

	.Toastify__toast--error {
		background-color: var(--error-color);
		border-radius: 4px;

		.Toastify__progress-bar {
			background-color: #a5230d;
		}
	}
}
