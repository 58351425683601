@import "../../assets/styles/app";

.root {
	@include flex-row();
	cursor: pointer;
	margin-bottom: 0;
	padding: 10px 0;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 100;

	.avatarContainer {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		text-align: right;

		.avatar {
			width: 30px;
			height: 30px;
			border-radius: 100%;

			img {
				max-width: 100%;
			}
		}

		.keyContainer {
			margin-right: 5px;
			margin-left: 24px;
			z-index: 1000;

			.copyContainer {
				.accountKey {
					@include font-header5(var(--account-color));

					@media (max-width: "1080px") {
						font-size: 10px;
					}
				}

				&:hover {
					.accountKey {
						color: var(--cpai-cyan) !important;
					}

					svg path {
						stroke: var(--cpai-cyan) !important;
					}
				}
			}
		}
	}

	& {
		right: 10%;

		@media (max-width: "1080px") {
			right: 40px;
		}

		@media (max-width: $screen-sm-min) {
			display: none;
		}
	}
}
