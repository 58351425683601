// Container
.container-fluid {
	padding-left: 30px;
	padding-right: 30px;
}

// Navbar
.navbar {
	border: none;
	height: $navbar-height;
	border-radius: $navbar-border-radius;
	margin-bottom: $navbar-margin-bottom;
	padding: 0.5rem 2.25rem;
}

// Progress
.progress {
	border-radius: 28px;

	.progress-bar {
		@include box-shadow(none);
		background-color: $green-100 !important;
		height: 16px !important;
	}

	.progress-bar.bg-info {
		background-color: $blue-60 !important;
	}

	.progress-bar.bg-warning {
		background-color: $yellow !important;
	}

	.progress-bar.bg-danger {
		background-color: $danger !important;
	}

	.progress-sm {
		height: 1rem;
	}

	.progress-xs {
		height: 5px;
	}
}

/* Modal */
.modal.fade.show {
	background-color: rgba(120, 144, 156, 0.4);
}

.modal-dialog {
	max-width: 730px !important;

	&.date-picker {
		width: 370px;
		margin: auto;

		.modal-body {
			padding-bottom: 0;
		}

		.modal-footer {
			justify-content: center;
			padding-top: 0;
		}
	}

	&.dark {
		.modal-header {
			border-bottom: solid 1px $cpai-gray;
		}

		.modal-footer {
			border-top: solid 1px $cpai-gray;
		}

		.modal-content {
			background-color: $cpai-black;

			.modal-title {
				color: $cpai-white;
			}

			.close {
				color: $cpai-white;
			}
		}
	}

	&.dialog-md {
		width: 650px;
		max-width: 90% !important;
		margin: auto;
	}

	&.dialog-xl {
		width: 960px;
		max-width: 90% !important;
		margin: auto;
	}

	&.dialog-sm {
		max-width: 445px !important;
	}

	.modal-header {
		border-bottom: none;
		padding: 12px 16px;
		background-color: $cpai-black;
	}

	.modal-title {
		width: 100%;
	}

	.modal-body {
		padding: 12px 16px;
		background-color: $cpai-black;
	}

	.modal-footer {
		border-top: none;
		padding: 12px 16px;
		position: relative;
		background-color: $cpai-black;

		& > * {
			margin: 0;
		}

		& > button + button {
			margin-left: 8px;
		}
	}

	&.bordered {
		.modal-header {
			border-bottom: 1px solid $cpai-gray;
		}

		.modal-footer {
			border-top: 1px solid $cpai-gray;
		}
	}
}

/* RADIO */
.custom-radio {
	input[type="radio"] {
		&:disabled:checked + .custom-control-label {
			&::before {
				background-color: $gray-60;
			}
		}
	}
}
/* Table widget */
.react-bs-table-container {
	&.wide-table {
		.react-bs-container-header,
		.react-bs-container-body {
			min-width: 1280px;
		}
	}

	.react-bs-container-body {
		overflow: visible;
	}

	.react-bs-table {
		overflow-x: auto;
		max-width: 100%;

		.react-bs-container-header,
		.react-bs-container-body {
			overflow: visible;
			min-width: 800px;
		}
	}
}

/* PAGER */
.react-bs-table-pagination {
	@include font-body14($blue-100);
	margin-top: 0;

	.page-item {
		.page-link {
			color: $blue-100;
		}
	}

	.active.page-item {
		.page-link {
			background-color: $blue-100;
			border-color: $blue-100;
			color: $white-color;
		}
	}
}

/* DATEPICKER */
.react-datepicker-popper {
	z-index: 100;
}

.react-datepicker__input-container {
	input {
		width: 100%;
		height: 46px;
		background: #ffffff;
		border: 1px solid #d9dbe1;
		flex: none;
		order: 1;
		flex-grow: 1;
		border-radius: 4px;
		@include font-header3($gray-100);
	}
}
