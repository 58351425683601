@import "../../assets/styles/app";

.desc {
	margin: 0 0 0 10px;
}

.root {
	@include flex-column();
	margin-left: auto;
	margin-right: auto;
	width: 380px;
	padding: 30px;
	border-radius: 16px;
	border: 1px solid var(--grey-2, #fbfbfb);
	box-shadow: 0 0 10px rgba(186, 243, 240, .7);
	max-width: 100%;
	background-color: $cpai-cyan-light;

	.title {
		@include font-header2(var(--cpai-blue));
		margin-bottom: 10px;
	}

	img {
		@include flex();
		max-width: 300px;
		max-height: 300px;
	}
}
