@import "../../../assets/styles/app";

.root {
	@include page-layout();

	.content {
		@include page-content(flex-start);

		.title {
			@include font-header1($cpai-green);
			text-align: center;

			@media (max-width: $screen-sm-min) {
				@include font-header2($cpai-green);
			}
		}

		.infos {
			margin-top: 8px;

			.description {
				@include font-body16(var(--gray-100));
				text-align: center;

				&.mb {
					margin-bottom: 8px;
				}

				&.mt {
					margin-top: 16px;
				}
			}
		}

		.buttons {
			@include flex();
			margin-top: 40px;
			margin-bottom: 40px;

			.button {
				width: 350px;
			}
		}
	}
}
