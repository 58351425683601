// Primary, Primary Dark, Primary Link, Primary border, Secondary
.btn {
	height: 48px;
	transition: background-color 0.5s, color 0.5s;

	&.btn-icon {
		padding: $padding-base-vertical;
	}

	&.btn-default {
		width: 350px;
		margin-bottom: 12px;
	}

	&.btn-primary {
		@include btn-styles(var(--cpai-white), $cpai-black);

		&:hover {
			@include btn-styles(var(--cpai-cyan), $cpai-black);
		}
	}

	&.btn-primary.link {
		@include btn-styles("transparent", var(--cpai-blue));

		&:hover {
			@include btn-styles("transparent", var(--cpai-blue));
		}
	}

	&.btn-info {
		@include border-btn-styles(var(--button-border), var(--button-text), $dark);
	}

	&.btn-border {
		@include border-btn-styles(var(--cpai-white),var(--cpai-white), $white-10);

		&:hover {
			@include btn-styles(var(--cpai-cyan-light), $cpai-black);
		}
	}

	&.btn-danger {
		@include btn-styles($danger, $white-color);

		&:hover {
			@include btn-styles($danger-dark, $white-color);
		}
	}

	&.btn-danger.btn-border {
		@include border-btn-styles($danger, $white-color, $white-color);
	}

	&.btn-link {
		@include font-button-text($blue-100);

		&:focus,
		&:active,
		&.active {
			border-color: none;
			box-shadow: none;
		}
	}
}
