@import "../../../assets/styles/app";

.root {
	.title {
		@include font-header2($cpai-white);
		text-align: center;

		&.dark {
			color: $cpai-black;
		}
	}

	.logoContainer {
		@include flex();
		margin-bottom: 16px;
	}

	.description {
		@include font-body16($cpai-white);
		text-align: center;

		&.dark {
			color: $cpai-white;
		}
	}

	.buttons {
		button:first-child {
			margin-right: 8px;
		}
	}
}
