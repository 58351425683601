/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root .title {
  font-family: "Audiowide-Regular";
  font-weight: normal;
  color: #E9ECE7;
  font-style: normal;
  font-size: 20px;
  line-height: auto;
  letter-spacing: 0%;
  margin-bottom: 16px;
  text-align: center;
}

.root .current {
  display: flex;
  align-items: center;
  justify-content: center;
}

.root .current img {
  max-width: 110px;
  max-height: 110px;
}

.root .footer {
  padding: 0 28px;
  width: 100%;
}

.root .footer .steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.root .footer .steps .step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #0A0617;
  flex: 1;
  cursor: pointer;
}

.root .footer .steps .step .name {
  font-family: "Play-Regular";
  font-weight: 700;
  color: #E9ECE7;
  font-style: normal;
  font-size: 16px;
  line-height: auto;
  letter-spacing: -0.02em;
}

.root .footer .steps .step .link {
  width: 200px;
  max-width: 100%;
  height: 1px;
  position: absolute;
  right: 50%;
  top: 9px;
  border-top: solid 2px #E9ECE7;
}

.root .footer .steps .step img {
  z-index: 100;
}

.root .footer .steps .step:first-child .link {
  display: none;
}

.root .footer .steps .step.active .name {
  color: var(--cpai-cyan);
}

.root .footer .steps .step.active .link {
  border-top-color: var(--cpai-white);
  margin-top: 10px;
}

.root .footer .description {
  font-family: "Play-Regular";
  font-weight: 700;
  color: #E9ECE7;
  font-style: normal;
  font-size: 16px;
  line-height: auto;
  letter-spacing: -0.02em;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

