/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root {
  width: 100%;
  max-width: 540px;
  margin-bottom: 8px;
}

.root .title {
  font-family: "Poppins";
  font-weight: 600;
  color: #3e4d53;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  line-height: normal;
}

.root .container {
  position: relative;
  width: 100%;
  margin-bottom: 0;
}

.root .container .copyInput {
  font-family: "Poppins";
  color: #3e4d53;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  height: 46px;
  padding-right: 50px;
  border: 1px solid #d9dbe1;
  background-color: #f5fafe;
}

.root .container .copyInput.valid {
  border: solid 1px var(--blue-100);
}

.root .container .inputSubText {
  font-family: "Poppins";
  color: #3e4d53;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  margin-top: 4px;
}

.root .container .inputSubText.copied {
  color: #1cd0af;
}

.root .container .icons {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 1px;
  top: 1px;
}

.root .container .icons .checkIcon {
  margin-right: 19px;
}

.root .container .icons .iconContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background-color: #e8e8e9;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.root .container .caption {
  font-family: "Poppins";
  color: #1cd0af;
  font-weight: bold;
  font-style: bold;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: -0.02em;
  margin-bottom: 0;
  margin-top: 8px;
}

.root .container .placeholder {
  font-family: "Poppins";
  color: #a3a4a6;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.02em;
}

