@import "../../assets/styles/app";

.root {
	@include flex-left();
	margin-bottom: 4px;

	.media {
		@include flex();

		img {
			margin-right: 8px;
		}

		svg {
			margin-right: 8px;
		}
	}

	.title {
		@include font-header2($gray-100);
	}

	.subTitle {
		@include font-body16($gray-100);
		margin-left: 11px;
	}
}
