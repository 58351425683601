@import "../../assets/styles/app";

.root {
	@include page-layout();
	background-color: $cpai-black;

	.content {
		@include page-content();

		padding-top: 120px;

		.section {
			width: 100%;
			margin-bottom: 8px;

			ol {
				padding-left: 20px;
				margin-bottom: 0;
			}

			.title {
				@include font-header2($cpai-white, "Audiowide-Regular", normal);
				margin-bottom: 8px;
			}

			.text {
				@include font-text(var(--cpai-white));
				max-width: 100%;
				word-wrap: break-word;
				white-space: pre-line;

				.note {
					font-family: "Play-Regular";
					color: $cpai-white;
					font-weight: 700;
				}

				a {
					color: var(--cpai-blue);
					font-weight: 700;
					letter-spacing: 0.64px;

					&.underline {
						text-decoration: underline;
					}
				}

				p.copyText {
					margin-left: -17px;
					margin-bottom: 8px;
				}
			}

			.instructionsHeader {
				@include font-header3($cpai-white, "Audiowide-Regular", normal);
				line-height: normal;
				margin-top: 8px;
			}

			.image {
				display: block;
				margin-top: 8px;
				margin-bottom: 4px;
				max-width: 100%;
			}

			li {
				.image {
					margin-left: -17px;
				}
			}
		}
	}
}
