/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.root .media {
  display: flex;
  align-items: center;
  justify-content: center;
}

.root .media img {
  margin-right: 8px;
}

.root .media svg {
  margin-right: 8px;
}

.root .title {
  font-family: "Audiowide-Regular";
  font-weight: normal;
  color: #1a1c21;
  font-style: normal;
  font-size: 20px;
  line-height: auto;
  letter-spacing: 0%;
  margin-bottom: 16px;
}

.root .subTitle {
  font-family: "Poppins";
  color: #1a1c21;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  margin-left: 11px;
}

