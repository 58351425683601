@import "../../assets/styles/app";

.root {
	padding: 10px 15px 9px 15px;
	position: relative;
	z-index: 4;

	display: flex;
	justify-content: center;
	align-items: center;

	.logo {
		max-width: 350px;
	}

	@media (max-width: $screen-sm-min) {
		.logo {
			max-width: 200px;
		}
	}
}
