/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root .title {
  font-family: "Audiowide-Regular";
  font-weight: normal;
  color: #E9ECE7;
  font-style: normal;
  font-size: 20px;
  line-height: auto;
  letter-spacing: 0%;
  margin-bottom: 16px;
  text-align: center;
}

.root .title.dark {
  color: #0A0617;
}

.root .logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.root .description {
  font-family: "Poppins";
  color: #E9ECE7;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.root .description.dark {
  color: #E9ECE7;
}

.root .buttons button:first-child {
  margin-right: 8px;
}

