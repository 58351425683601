@import "../../assets/styles/app";

.root {
	max-width: 100%;
	
	.button {
		width: 350px;
		max-width: 100%;
		height: 48px;
		text-align: center;
	}
}
