/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root {
  position: relative;
  flex: 1;
}

.root .title {
  font-family: 600;
  font-weight: 600;
  color: #1a1c21;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 2px;
}

.root .title.error {
  color: #dc3545 !important;
}

.root .input {
  font-family: "Poppins";
  font-weight: 400;
  color: #1a1c21;
  font-style: normal;
  font-size: 20px;
  line-height: auto;
  letter-spacing: 0%;
  margin-bottom: 16px;
  width: 100%;
  max-width: 100%;
  height: 46px;
  border-radius: 4px;
  padding: 0 10px;
  border: 1px solid #ced4da;
}

.root .input.error {
  border-color: #dc3545;
}

.root .input:disabled {
  background-color: #f5fafe;
}

.root .input::placeholder {
  font-family: "Poppins-Light";
  font-weight: 400;
  color: #a3a4a6;
  font-style: normal;
  font-size: 20px;
  line-height: auto;
  letter-spacing: 0%;
  margin-bottom: 16px;
}

.root .label {
  font-family: "Poppins";
  color: #3e4d53;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  margin-top: 4px;
}

.root .label span {
  display: inline-block;
  font-family: "Poppins-Bold";
}

.root .errorMessage {
  font-family: "Poppins";
  color: #dc3545;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  margin-top: 4px;
}

