@import "../../assets/styles/app";

.root {
	position: relative;
	overflow: visible;

	.titleHeader {
		@include flex();
		justify-content: space-between;
		width: 100%;
		height: 40px;

		img {
			cursor: pointer;
		}

		.total {
			@include font-body20($gray-100, "Poppins", 400);
		}
	}

	.title {
		@include font-header2($gray-100);
	}

	.mainTable {
		height: 100%;

		.dotRow {
			@include flex-left();

			.dot {
				display: inline-block;
				width: 10px;
				height: 10px;
				border-radius: 20px;
				margin-right: 6px;
			}
		}

		.centerTh {
			text-align: center !important;
		}

		.rightAlignTh {
			text-align: right !important;
			padding-right: 10px;
		}

		.brokerTh {
			padding-right: 0;
		}

		.blacklisted {
			color: $bad;
		}

		.row {
			height: 54px;
		}

		td,
		td > * {
			overflow: visible;
		}

		th {
			@include font-body14(#2d3540, "Poppins", 600);
			@include user-select();
			border: none;
			height: 50px;
			padding-left: 8px;
			padding-right: 8px;
		}

		td {
			border: none !important;
			@include font-body14($gray-100, "Poppins", 400);
			vertical-align: middle;
			padding-left: 8px;
			padding-right: 8px;

			@include ellipsis-text();

			.rightsCell {
				display: flex;
				flex-direction: row;
			}

			.centerCell {
				display: flex;
				justify-content: center;
			}

			&.broker {
				padding-right: 0;
			}
		}

		.description {
			@include font-body14($gray-100);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.emptyDescription {
			color: $gray-40;
		}

		.hover {
			&:hover {
				background-color: $white-10;
			}

			td.highlight {
				cursor: pointer;

				&:hover {
					@include highlight-table-cell(var(--app-color));

					& > a {
						color: var(--app-color);
					}
				}

				span:hover,
				div:hover {
					@include highlight-table-cell(var(--app-color));
				}

				& > a {
					cursor: pointer;
					color: $gray-100;
					font-weight: 600;
					text-decoration: none;
				}
			}

			&.selected {
				background-color: $white-10;

				svg path {
					stroke: $blue-100;
				}
			}

			&.selected {
				background-color: $white-10;
			}
		}
	}

	.tableCell {
		@include flex();
		justify-content: flex-start;

		@include font-body14($gray-100);
		text-decoration: none;
		cursor: pointer;
	}

	.actions {
		@include flex-left();

		.tableFillIcon,
		.deleteIcon,
		.tableStrokeIcon {
			svg {
				width: var(--icon-size);
				height: var(--icon-size);
			}
		}

		.tableFillIcon,
		.tableStrokeIcon {
			margin-right: var(--margin);
			@include flex();
		}

		.tableStrokeIcon:hover {
			svg path {
				stroke: $blue-100;
			}
		}

		.tableFillIcon:hover {
			svg path {
				fill: $blue-100;
			}
		}

		.deleteIcon:hover {
			svg path {
				fill: $danger;
			}
		}
	}

	.pagination {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
		padding: 0 30px;
		height: 40px;

		ul {
			li:last-child {
				a {
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
				}
			}
		}
	}
}
