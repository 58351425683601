/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 540px;
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.root .sectionTitle {
  font-family: "Poppins-Medium";
  font-weight: 600;
  color: var(--app-color);
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-bottom: 4px;
}

.root .text {
  font-family: "Audiowide-Regular";
  font-weight: normal;
  color: #1a1c21;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  line-height: normal;
}

.root .copyWidget {
  margin-bottom: 0;
}

