/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.root .highlight {
  background-color: rgba(209, 209, 209, 0.5);
  font-weight: normal;
}

