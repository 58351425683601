@import "../../assets/styles/app";


.root {
	@include page-background('../../assets/images/town.png');
	
	.content {

		.title {
			@include font-header-glow($cpai-white);
			text-align: left;
			margin-bottom: 20px;
			color: $cpai-white;
			font-size: 80px;
		}

		.description {
			@include font-text-bold(var($cpai-white));
			text-align: left;
			word-wrap: break-word;
			white-space: pre-wrap;
			color: $cpai-white;
		

			&.mb {
				margin-bottom: 8px;
			}

			&.mt {
				margin-top: 16px;
			}

			span {
				font-weight: 700;
				font-family: Play-Bold;
			}
		}

			.text {
				@include font-header2(var(--app-color));
				position: absolute;
				left: 0;
				top: 0;
			}

			img {
				width: 360px;
				height: auto;
				border-radius: 16px;
				max-width: 100%;
			}
		}
	}

