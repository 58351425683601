/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.container {
  max-width: 80%;
  /* Adjust max-width as needed */
  margin: 0 auto;
  /* Center the container horizontally */
  background-color: rgba(10, 6, 23, 0.7);
  /* Background color */
  border-radius: 10px;
  /* Rounded corners */
  padding: 20px;
  /* Add padding around the content */
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    /* Full width for single column layout */
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: row;
    /* Single column layout for smaller screens */
    align-items: center;
    /* Center items vertically */
    padding: 10px;
    /* Adjust padding for smaller screens */
  }
}

.container .responsive-image {
  max-width: 100%;
  /* Make the image responsive */
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.root {
  min-height: 100vh;
}

.root .container {
  background-color: rgba(10, 6, 23, 0.7);
  padding: 30px 30px;
  margin: 165px 165px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 90%;
}

.root .content .title {
  font-family: "Audiowide-Regular";
  font-weight: normal;
  color: #E9ECE7;
  font-style: normal;
  font-size: 60px;
  line-height: auto;
  letter-spacing: 0%;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
  -webkit-text-stroke: 1px black;
  color: rgba(0, 0, 0, 0);
  text-align: left;
  color: #E9ECE7;
  font-size: 40px;
  max-width: 90%;
  margin-top: 20px;
}

.root .content .infos {
  margin-top: 20px;
  margin-bottom: 30px;
}

.root .content .infos .description {
  font-family: "Play-Regular";
  font-weight: 700;
  color: var(--cpai-white);
  font-style: normal;
  font-size: 16px;
  line-height: auto;
  letter-spacing: -0.02em;
  margin-bottom: 16px;
  text-align: left;
  max-width: 100%;
  word-wrap: break-word;
}

.root .content .infos .description.mb {
  margin-bottom: 8px;
}

.root .content .infos .description.mt {
  margin-top: 16px;
}

.root .content .cards {
  padding: 20px;
  margin: auto;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, 350px);
  justify-content: center;
  width: 100%;
  color: #BAF3F0;
  box-shadow: 0 0 10px rgba(186, 243, 240, 0.7);
}

@media (max-width: 768px) {
  .root .content .cards {
    grid-template-columns: repeat(auto-fill, 330px);
  }
}

.root .content .buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  max-width: 100%;
}

.root .content .buttons .button {
  width: 350px;
  max-width: 100%;
}

@media (max-width: 768px) {
  .root .content {
    padding-top: 100px;
  }
}

