@import "../../../assets/styles/app";

.root {
	border: 2px solid #E9ECE7;
	border-radius: 4px;
	margin-top: 16px;
	margin-bottom: 16px;
	width: 348px;
	max-width: 100%;

	.foxContainer {
		@include flex();
		padding: 0 14px;
		height: 80px;
		cursor: pointer;

		.foxRighPanel {
			margin-left: 20px;
			margin-right: 20px;
			flex: 1;

			.meta {
				@include font-header4($cpai-white);
			}

			.subTitle {
				@include font-body14($cpai-white, "Play-Regular");
			}
		}
	}

	.download {
		@include flex();
		background-color: $cpai-white;
		height: 70px;

		p {
			@include font-text($cpai-black);

			a {
				color: var(--cpai-blue);
				letter-spacing: 0.04em;
			}
		}
	}
}
