/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root {
  display: flex;
  margin-bottom: 20px;
}

.root .tab,
.root .activeTab {
  display: flex;
  height: 40px;
}

.root .tab {
  box-shadow: inset 0px -1px 0px #dee2e6;
}

.root .tab a {
  font-family: "Poppins";
  color: var(--cpai-white);
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  padding: 8px 16px;
}

.root .activeTab {
  box-shadow: none;
}

.root .activeTab a {
  font-family: "Poppins";
  color: #ffffff;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  background-color: var(--cpai-white);
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--cpai-white);
}

