@import "../../../assets/styles/app";

.root {
	display: inline-block;

	span {
		margin-left: 4px;
		@include font-caption($cpai-white);
	}
}
