@import "../../../assets/styles/app";

.root {
	position: relative;
	display: inline-flex;
	align-items: center;
	max-width: 100%;

	@include font-text($cpai-white, "Play-Regular", normal);

	.highlighter {
		//white-space: nowrap;

		&,
		div {
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
		}
	}

	span {
		//white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-block;
		max-width: 100%;
	}

	.icon {
		@include flex();
		width: 20px;
		height: 20px;
		margin-left: 8px;
		margin-bottom: 2px;
	}

	&.rightCell {
		display: inline-flex;
		width: 100%;
		padding-right: 10px;
		justify-content: flex-end;
	}
}
