@import "../../assets/styles/app";

.root {
	width: 100%;

	.logo {
		@include flex();
		margin-top: 0px;
		margin-bottom: 10px;

		img {
			max-width: 160px;
		}
	}

	.note {
		@include font-header5($gray-100, "Poppins", 400);
		text-align: center;
	}
}
