@import "../../../../assets/styles/app";

.btnSuccess {
	background-color: var(--app-color) !important;
	border-color: var(--app-color) !important;
	color: $white !important;
}

.btnFailure {
	background-color: var(--error-color) !important;
	border-color: var(--error-color) !important;
	color: $white !important;
}

.root {
	height: auto;
	max-width: 100%;

	.header {
		@include flex-left();
		width: 100%;
		margin-left: 16px;
		margin-right: 16px;

		.search {
			max-width: 300px;
		}

		.selector {
			position: relative;
			@include font-body14($gray-100);
			margin-left: auto;

			@media (max-width: $screen-sm-min) {
				text-align: right;
				width: 160px;
			}

			img {
				position: absolute;
				left: -25px;
				top: 0;
				max-width: 20px;
			}

			* {
				cursor: pointer;
			}

			input {
				margin-right: 8px;
			}
		}
	}
}
