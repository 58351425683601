@import "../../assets/styles/app";
@import "../../components/Container/Container.scss";

.root {
	@include page-layout();

	.container {
		@include container(rgba(10,6,23,.7), 30px, 30px, 165px, 165px);
	}

	.content {
	

		.title {
			@include font-header-glow($cpai-white);
			text-align: left;
			color: $cpai-white;
			font-size: 40px;
			max-width: 90%;
			margin-top: 20px;

		}
		
		.infos {
			margin-top: 20px;
			margin-bottom: 30px;

			.description {
				@include font-text(var(--cpai-white));
				margin-bottom: 16px;
				text-align: left;
				max-width: 100%;
				word-wrap: break-word;

				&.mb {
					margin-bottom: 8px;
				}

				&.mt {
					margin-top: 16px;
				}
			}
		}

		.cards {
			padding: 20px; 
			margin: auto;
			display: grid;
			grid-gap: 16px;
			grid-template-columns: repeat(auto-fill, 350px);
			justify-content: center;
			width: 100%;
			color: $cpai-cyan-light;
			box-shadow: 0 0 10px rgba(186, 243, 240, .7);

			@media (max-width: $screen-sm-min) {
				grid-template-columns: repeat(auto-fill, 330px);
			}
		}

		.buttons {
			@include flex();
			margin-top: 40px;
			max-width: 100%;

			.button {
				width: 350px;
				max-width: 100%;
			}
		}
	   
		@media (max-width: $screen-sm-min) {
			padding-top: 100px; // Add top padding for smaller breakpoints
		}
	}

}