@import "../../../assets/styles/app";

.root {
	.title {
		@include font-header2($cpai-white);
		text-align: center;

		&.dark {
			color: $cpai-white;
		}
	}

	.iconContainer {
		@include flex();
		margin-top: 16px;
		margin-bottom: 16px;
	}

	.description {
		@include font-text($cpai-white);
		text-align: center;

		&.dark {
			color: $cpai-white;
		}
	}

	.download {
		@include flex();
		width: 100%;
		height: 70px;

		p {
			@include font-text($cpai-white);

			a {
				color: var(--cpai-blue);
			}
		}
	}
}
