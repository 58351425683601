@import "../../../assets/styles/app";

.root {
	
	.content {
		
		.title {
			@include font-header-glow($cpai-white);
			text-align: left;
			color: $cpai-white;
			margin-top: 160px;
		}

		.subtitle {
			@include font-header2($cpai-white);
			text-align: left;
		}

		.description {
			@include font-text($cpai-white);
			text-align: left;
			max-width: auto;
			word-wrap: break-word;

			&.mb {
				margin-bottom: 8px;
			}

			&.mt {
				margin-top: 24px;
			}
		}

		.buttons {
			@include flex-left();
			margin-top: 24px;
			max-width: 90%;
		}
		}

		.inputContainer {
			@include flex-left();
			margin-top: 40px;
			max-width: 100%;
			max-width: 90%;

			.input {
				width: 540px;
				max-width: 100%;
				justify-content: left;
				max-width: 90%;

				input {
					border: 1px solid #ced4da;
					border-radius: 4px;
				}
			}

			.inputTitle {
				@include font-text-bold($cpai-white);
				text-align: left;
				margin-bottom: 8px;
			}
		
			.responsive-image {
				max-width: 50%; /* Make the image responsive */
				height: auto;
			  }	
		}
	}

