@import "../../assets/styles/app";

.root {
	width: 100%;
	max-width: 540px;
	margin-bottom: 8px;

	.title {
		@include font-header5($gray-80, "Poppins", 600);
		line-height: normal;
	}

	.container {
		position: relative;
		width: 100%;
		margin-bottom: 0;

		.copyInput {
			@include font-body16($gray-80);
			height: 46px;
			padding-right: 50px;
			border: 1px solid $gray-20;
			background-color: $white-10;

			&.valid {
				border: solid 1px var(--blue-100);
			}
		}

		.inputSubText {
			@include font-body12($gray-80);
			margin-top: 4px;

			&.copied {
				color: $green-dark;
			}
		}

		.icons {
			@include flex-row();
			position: absolute;
			right: 1px;
			top: 1px;

			.checkIcon {
				margin-right: 19px;
			}

			.iconContainer {
				position: relative;
				@include flex();

				width: 44px;
				height: 44px;
				background-color: $gray-10;
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}

		.caption {
			@include font-caption($green_dark, "Poppins", 400);
			margin-bottom: 0;
			margin-top: 8px;
		}

		.placeholder {
			@include font-body20($gray-40, "Poppins", 400);
		}
	}
}
