/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
}

.root .avatarContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.root .avatarContainer .avatar {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.root .avatarContainer .avatar img {
  max-width: 100%;
}

.root .avatarContainer .keyContainer {
  margin-right: 5px;
  margin-left: 24px;
  z-index: 1000;
}

.root .avatarContainer .keyContainer .copyContainer .accountKey {
  font-family: "Poppins-Medium";
  font-weight: 600;
  color: var(--account-color);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

@media (max-width: 1080px) {
  .root .avatarContainer .keyContainer .copyContainer .accountKey {
    font-size: 10px;
  }
}

.root .avatarContainer .keyContainer .copyContainer:hover .accountKey {
  color: var(--cpai-cyan) !important;
}

.root .avatarContainer .keyContainer .copyContainer:hover svg path {
  stroke: var(--cpai-cyan) !important;
}

.root {
  right: 10%;
}

@media (max-width: 1080px) {
  .root {
    right: 40px;
  }
}

@media (max-width: 768px) {
  .root {
    display: none;
  }
}

