@font-face {
	font-family: "Audiowide-Regular";
	src: url("../fonts/Audiowide-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Play-Bold";
	src: url("../fonts/Play-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: bold;
}

@font-face {
	font-family: "Play-Regular";
	src: url("../fonts/Play-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

	@font-face {
	font-family: "Poppins";
	src: url("../fonts/Poppins-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Poppins-Medium";
	src: url("../fonts/Poppins-Medium.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Poppins-Bold";
	src: url("../fonts/Poppins-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: bold;
}

@font-face {
	font-family: "Poppins-SemiBold";
	src: url("../fonts/Poppins-SemiBold.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Poppins-Thin";
	src: url("../fonts/Poppins-Thin.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

/* Italics */
@font-face {
	font-family: "Poppins-Medium-Italic";
	src: url("../fonts/Poppins-MediumItalic.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Poppins-Light";
	src: url("../fonts/Poppins-Light.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Poppins-Light-Italic";
	src: url("../fonts/Poppins-LightItalic.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Poppins-Thin-Italic";
	src: url("../fonts/Poppins-ThinItalic.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
} 
