/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root {
  min-height: 100vh;
  background-color: #0A0617;
}

.root .content {
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: left;
  height: 100%;
  width: 100%;
  padding: 100px min(10%, 165px);
  padding-top: 120px;
}

@media (max-width: 1200px) {
  .root .content {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 768px) {
  .root .content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.root .content .section {
  width: 100%;
  margin-bottom: 8px;
}

.root .content .section ol {
  padding-left: 20px;
  margin-bottom: 0;
}

.root .content .section .title {
  font-family: "Audiowide-Regular";
  font-weight: normal;
  color: #E9ECE7;
  font-style: normal;
  font-size: 20px;
  line-height: auto;
  letter-spacing: 0%;
  margin-bottom: 16px;
  margin-bottom: 8px;
}

.root .content .section .text {
  font-family: "Play-Regular";
  font-weight: 700;
  color: var(--cpai-white);
  font-style: normal;
  font-size: 16px;
  line-height: auto;
  letter-spacing: -0.02em;
  max-width: 100%;
  word-wrap: break-word;
  white-space: pre-line;
}

.root .content .section .text .note {
  font-family: "Play-Regular";
  color: #E9ECE7;
  font-weight: 700;
}

.root .content .section .text a {
  color: var(--cpai-blue);
  font-weight: 700;
  letter-spacing: 0.64px;
}

.root .content .section .text a.underline {
  text-decoration: underline;
}

.root .content .section .text p.copyText {
  margin-left: -17px;
  margin-bottom: 8px;
}

.root .content .section .instructionsHeader {
  font-family: "Audiowide-Regular";
  font-weight: normal;
  color: #E9ECE7;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  line-height: normal;
  margin-top: 8px;
}

.root .content .section .image {
  display: block;
  margin-top: 8px;
  margin-bottom: 4px;
  max-width: 100%;
}

.root .content .section li .image {
  margin-left: -17px;
}

