/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.root .content {
  margin: 40px 10% 0;
}

.root .content .title {
  font-family: "Audiowide-Regular";
  font-weight: normal;
  color: #d07567;
  font-style: normal;
  font-size: 60px;
  line-height: auto;
  letter-spacing: 0%;
  text-align: center;
}

.root .content .description {
  font-family: "Poppins";
  color: var(--gray-100);
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  text-align: center;
}

.root .content .buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.root .content .buttons .button {
  width: 350px;
}

.root .content .image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

@media (max-width: 1080px) {
  .root .header {
    padding: 81px 40px 20px;
  }

  .root .content {
    margin: 20px 40px;
  }
}

@media (max-width: 700px) {
  .root .header {
    padding: 81px 16px 16px;
  }

  .root .content {
    margin: 20px 16px;
  }
}

