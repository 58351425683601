@import "../../assets/styles/app";

.root {
	width: 100%;
	margin-bottom: 16px;

	.title {
		@include font-header3($gray-60, "Poppins", 600);
	}

	.copyWidget {
		position: relative;
		display: inline-flex;
		align-items: center;
		margin-bottom: 0;
		cursor: pointer;

		&:hover {
			.text {
				letter-spacing: 0.04em !important;
				color: $cpai-green !important;
				font-weight: 600 !important;
			}

			svg path {
				stroke: $cpai-green;
			}
		}

		.copyIcon {
			@include flex();
			height: 100%;
			margin-left: 4px;
		}
	}
}
