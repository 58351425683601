/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root .title {
  font-family: "Audiowide-Regular";
  font-weight: normal;
  color: #E9ECE7;
  font-style: normal;
  font-size: 20px;
  line-height: auto;
  letter-spacing: 0%;
  margin-bottom: 16px;
  text-align: center;
}

.root .title.dark {
  color: #E9ECE7;
}

.root .iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.root .description {
  font-family: "Play-Regular";
  font-weight: 700;
  color: #E9ECE7;
  font-style: normal;
  font-size: 16px;
  line-height: auto;
  letter-spacing: -0.02em;
  text-align: center;
}

.root .description.dark {
  color: #E9ECE7;
}

.root .download {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
}

.root .download p {
  font-family: "Play-Regular";
  font-weight: 700;
  color: #E9ECE7;
  font-style: normal;
  font-size: 16px;
  line-height: auto;
  letter-spacing: -0.02em;
}

.root .download p a {
  color: var(--cpai-blue);
}

