@import "../../assets/styles/app";

.root {
	@include flex-column();
	margin-left: auto;
	margin-right: auto;
	padding: 30px;
	width: 380px;
	max-width: 380px;
	box-shadow: 0 0 10px rgba(186, 243, 240, .7);
	max-width: 100%;
	background-color: $cpai-cyan-light;
	border-radius: 16px;

	.sectionTitle {
		@include font-header3(var(--cpai-black), "Poppins-Medium", 600);
		margin-bottom: 4px;
	}

	.text {
		@include font-text($cpai-black);
		line-height: normal;
		margin-bottom: 10px;

		a {
			color: var(--cpai-blue);
			font-weight: 700;
			letter-spacing: 0.07em;
		}
	}

	.amount {
		@include font-header3($cpai-black);
		line-height: normal;
		margin-bottom: 10px;
		font-weight: 700;
	}

	.header {
		@include font-header2(var(--cpai-black));
		line-height: normal;
		margin-bottom: 20px;
		font-weight: 700;
		letter-spacing: 0.07em;
	}

	img {
		@include flex();
		width: 922px;
		max-width: 100%;
		margin-top: 6px;
	}
}
