/* ======================================================================== */

/*
  * Media queries breakpoints
  * ======================================================================== */

/* Extra small screen / phone */

/* Small screen / tablet */

/* Medium screen / desktop */

/* Large screen / wide desktop */

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  width: 380px;
  max-width: 380px;
  box-shadow: 0 0 10px rgba(186, 243, 240, 0.7);
  max-width: 100%;
  background-color: #BAF3F0;
  border-radius: 16px;
}

.root .sectionTitle {
  font-family: "Poppins-Medium";
  font-weight: 600;
  color: var(--cpai-black);
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-bottom: 4px;
}

.root .text {
  font-family: "Play-Regular";
  font-weight: 700;
  color: #0A0617;
  font-style: normal;
  font-size: 16px;
  line-height: auto;
  letter-spacing: -0.02em;
  line-height: normal;
  margin-bottom: 10px;
}

.root .text a {
  color: var(--cpai-blue);
  font-weight: 700;
  letter-spacing: 0.07em;
}

.root .amount {
  font-family: "Audiowide-Regular";
  font-weight: normal;
  color: #0A0617;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  line-height: normal;
  margin-bottom: 10px;
  font-weight: 700;
}

.root .header {
  font-family: "Audiowide-Regular";
  font-weight: normal;
  color: var(--cpai-black);
  font-style: normal;
  font-size: 20px;
  line-height: auto;
  letter-spacing: 0%;
  margin-bottom: 16px;
  line-height: normal;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 0.07em;
}

.root img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 922px;
  max-width: 100%;
  margin-top: 6px;
}

