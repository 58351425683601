@import "../../../assets/styles/app";

.root {
	@include page-layout();
	justify-content: flex-start;

	.tabs {
		width: 100%;
	}

	.content {
		@include page-content(flex-start);
		position: relative;

		.title {
			@include font-header1(var(--app-color));
			text-align: center;
		}
	}
}
