@import "../../assets/styles/app";

.root {
	display: flex;
	margin-bottom: 20px;

	.tab,
	.activeTab {
		display: flex;
		height: 40px;
		//min-width: 100px;
	}

	.tab {
		box-shadow: inset 0px -1px 0px $gray-15;

		a {
			@include font-body16(var(--cpai-white));
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: pre;
			padding: 8px 16px;
		}
	}

	.activeTab {
		box-shadow: none;

		a {
			@include font-body16($white-color);
			background-color: var(--cpai-white);
			border-radius: 4px 4px 0px 0px;
			border: 1px solid var(--cpai-white);
		}
	}
}
